import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loader from "./components/Loader";

// Lazy load pages and components
const ListRestaurants = lazy(() => import("./components/ListRestaurants"));
const HotelPage = lazy(() => import("./components/Hotel"));
const Restaurant = lazy(() => import("./pages/restaurant"));
const OrderPlacement = lazy(() => import("./pages/orderPlacement"));
const GuestInformation = lazy(() => import("./pages/guestInformation"));
const Checkout = lazy(() => import("./pages/checkout"));
const Home = lazy(() => import("./pages/home"));
const HotelServices = lazy(() => import("./pages/hotel_services"));
const OrderSuccess = lazy(() => import("./pages/orderSuccess"));
const Error500 = lazy(() => import("./pages/error500"));
const Main = lazy(() => import("./pages/dashboard/main"));
const Layout = lazy(() => import("./layouts/layout"));
const Profile = lazy(() => import("./pages/dashboard/profile"));
const Reservations = lazy(() => import("./pages/dashboard/reservations"));
const Orders = lazy(() => import("./pages/dashboard/orders"));
const SignUp = lazy(() => import("./pages/signUp"));

const App = () => {
    const { pathname } = window.location;

    const RedirectToNewFormat = () => {
        if (
            pathname !== "/" &&
            !pathname.startsWith("/hotel/") &&
            !pathname.startsWith("/restaurants/") &&
            !pathname.startsWith("/dashboard/")
        ) {
            const hotelName = pathname.replace("/", ""); // Extract the hotel name
            return <Navigate to={`/hotel/${hotelName}`} />;
        }

        return null;
    };

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Layout>
                    <Routes>
                        <Route exact path="/restaurants" element={<ListRestaurants />} />
                        <Route path="/hotel/:slug" element={<HotelPage />} />
                        <Route exact path="/" element={<Home />} />
                        <Route path="/account/signin" element={<OrderPlacement />} />
                        <Route path="/account/signup" element={<SignUp />} />
                        <Route path="/account/guest" element={<GuestInformation />} />
                        <Route path="/account/checkout" element={<Checkout />} />
                        <Route path="/restaurants/:slug" element={<Restaurant />} />
                        <Route path="/services/:slug" element={<HotelServices />} />
                        <Route path="/order_success" element={<OrderSuccess />} />
                        <Route path="*" element={<RedirectToNewFormat />} />
                        <Route exact path="/dashboard" element={<Main />}>
                            <Route exact path="/dashboard" element={<Navigate to="/dashboard/profile" replace />} />
                            <Route exact path="profile" element={<Profile />} />
                            <Route path="reservations" element={<Reservations />} />
                            <Route path="orders" element={<Orders />} />
                        </Route>
                        <Route path="error" element={<Error500 />} />
                    </Routes>
                </Layout>
            </Suspense>
        </BrowserRouter>
    );
};

export default App;
